import { styled } from 'styles';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  color: white;
`;

export const Content = styled.div`
  text-align: center;
`;

export const DeltaLogo = styled.img`
  display: inline;
  height: 48px;
  width: auto;
  margin: 3rem;
`;

export const RedirectText = styled.p`
  font-size: 1.8rem;
  font-weight: 400;
`;

export const Link = styled.a`
  margin-top: 1rem;
  display: block;
  font-size: 1.4rem;
  color: white;
  font-weight: 300;
  text-decoration: underline;
`;
